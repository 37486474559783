import React from "react";
import Layout from "../components/Layout";
import NavTwo from "../components/NavTwo";
import PageHeader from "../components/PageHeader";
import NewsDetails from "../components/NewsDetails";
import Footer from "../components/Footer";
import Page404 from "../components/Page404";

const NewsDetailsPage = () => (
  <Layout pageTitle={`404 page not found | EcomScout`}>
    <Page404 />
  </Layout>
);

export default NewsDetailsPage;
