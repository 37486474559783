import React from "react";
//import BannerMoc from "../assets/images/mocs/banner-moc-1-1.png";
import BannerMoc from "../assets/images/home/mobile.png";
import { BASE_APP_URL, BASE_FRONT_URL } from "../config/constants";
const Page404 = () => {
  return (
    <section className="banner-one banner-404">
      <span className="banner-one__shape-1"></span>
      <span className="banner-one__shape-2"></span>
      <span className="banner-one__shape-3"></span>
      <span className="banner-one__shape-4"></span>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="banner-one__content">
              <h1 className="banner-one__title text-center">
                Page not found
              </h1>
            </div>
          </div>
        </div>
        <div className="mt-5 text-center">
          <a href={`${BASE_FRONT_URL}`} className="banner-one__btn thm-btn">
            <span>Go to Homepage</span>
          </a>
        </div>
      </div>
    </section>
  );
};
export default Page404;
